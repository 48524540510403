import React from 'react'
import { srcSetProps, sanityImageUrl } from '../../../utils/format'
import { Link } from 'gatsby'
import styles from './style.module.sass'

export default ({ header }) => {
  if (!header) return null

  return (
    <div className={styles.header}>
      <div className={styles.image}>
        <img className={styles.imageSrc} {...srcSetProps(sanityImageUrl(header.image))} alt={header.image?.caption} />
      </div>
      <div className={styles.wrap}>
        <div className={styles.text}>
          <h1 className={styles.title}>{header.title}</h1>
          <Link to={header.buttonUrl} className={styles.button}>{header.buttonTitle}</Link>
        </div>
      </div>
    </div>
  )
}
